// CookieConsent.js
import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import './CookieConsent.css'; // Asumiendo que quieras agregar algo de CSS

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const viewerId = Cookies.get('viewerId');
    // Si no se ha guardado el consentimiento en las cookies, mostrar el mensaje
    if (!viewerId) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    const viewerId = uuidv4();
    Cookies.set('viewerId', viewerId, { expires: 365 });
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <p>Este sitio utiliza cookies para mejorar la experiencia de usuario. Al continuar navegando, aceptas el uso de cookies.</p>
      <button onClick={handleAccept}>Aceptar</button>
    </div>
  );
};

export default CookieConsent;
