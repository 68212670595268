import { BrowserRouter } from "react-router-dom";
import Router from "./navigation/router";
import './App.css';
import CookieConsent from './components/CookieConsent';

function App() {
  return (
    <div>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <CookieConsent />
    </div>
  );
}

export default App;
