import { useEffect, useState } from "react";
import { getApi } from "../services/api";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { encrypt } from "../services/cipher";

const Photo = () => {
  const [imgUrl, setImgUrl] = useState(undefined);
  const [posterUrl, setPosterUrl] = useState(undefined);
  const [code, setCode] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [firstFetch, setFirstFetch] = useState(false);
  const [ipAddress, setIpAddress] = useState(undefined);
  const [socialMetaData, setSocialMetaData] = useState(undefined);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (location.search && ipAddress) fetchImage();
  }, [location?.search, ipAddress]);

  useEffect(() => {
    const fetchIPAddress = async () => {
      try {
        const response = await fetch('https://api.ipify.org?format=json');
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error('Error fetching IP address:', error);
        setIpAddress('unknown');
      }
      setFirstFetch(true);
    };

    fetchIPAddress();

  }, [])



  const fetchImage = async () => {

    setLoading(true);

    const idViewer = Cookies.get('viewerId') || "anonymous";
    const viewerIp = ipAddress || "unknown";
    const viewerParamsString = "idViewer=" + idViewer + "&viewerIp=" + viewerIp;

    const paramsViewerCifrados = encrypt(viewerParamsString);

    const url = "/photo/share/" + window.location.search + "&viewerParams=" + paramsViewerCifrados;

    try {
      await getApi(url).then((data) => {
        if (data && data.imageUrl) {
          setImgUrl(data.imageUrl);
        }
        if (data && data.posterUrl) {
          setPosterUrl(data.posterUrl);
        }
        if (data && data.eventCode) {
          setCode(data.eventCode);
        }
        if (data && data.socialMetaData) {
          setSocialMetaData(data.socialMetaData);
        }
      })
    } catch (error) {
      console.error("Error al obtener la imagen", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div className="mt-2 has-text-centered">
    <header className="event-header p-3" style={{ zIndex: 1000 }}>
      <div className="level">
        <div className="level-left">
          <div className="level-item event-title">
            <div className="d-flex">
              <a href="https://revopic.com/es/help-attendee/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare" target="_blank" rel="noopener noreferrer" className="image">
                <img className="is-rounded" style={{ width: '60px' }} src="https://revopic.com/wp-content/uploads/2023/11/Revopic-logo.png" alt="Revopic" />
              </a>
            </div>
          </div>
        </div>
        <div className="level-right"></div>
      </div>
    </header>
    <center><progress className="progress is-primary mt-3" style={{ maxWidth: '60%' }}></progress></center>
    <center className="pb-3">
      <p className="pb-3">
        <a href="https://revopic.com/es/help-attendee/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare" target="_blank" rel="noopener noreferrer" className="image">
          <img className="is-rounded" style={{ width: '60px' }} src="https://revopic.com/wp-content/uploads/2023/11/Revopic-logo.png" alt="Revopic" />
        </a>
        Powered by <strong><a href="https://revopic.com/es/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare">Revopic</a></strong>
      </p>
    </center>
  </div>

  if (imgUrl === undefined && firstFetch) return <>
    <div className="notification is-primary mr-3 ml-3 mt-3">
      <button className="delete"></button>
      <center>{t('No se ha podido cargar la imagen')}</center>
      <center>{t('La URL proporcionada no es válida, por favor verifique que la URL sea correcta')}</center>
    </div>
    <center className="pb-3">
      <p className="pb-3">
        <a href="https://revopic.com/" target="_blank" rel="noopener noreferrer" className="image">
          <img className="is-rounded" style={{ width: '60px' }} src="https://revopic.com/wp-content/uploads/2023/11/Revopic-logo.png" alt="Revopic" />
        </a>
        Powered by <strong><a href="https://revopic.com/es/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare">Revopic</a></strong>
      </p>
    </center>
  </>;

  return (
    <>
      <header className="event-header p-3" style={{ zIndex: 1000 }}>
        <div className="level">
          <div className="level-left">
            <div className="level-item event-title">
              <div className="d-flex">
                <a href="https://revopic.com/es/help-attendee/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare" target="_blank" rel="noopener noreferrer" className="image">
                  <img className="is-rounded" style={{ width: '60px' }} src="https://revopic.com/wp-content/uploads/2023/11/Revopic-logo.png" alt="Revopic" />
                </a>
              </div>
            </div>
          </div>
          <div className="level-right"></div>
        </div>
      </header>

      <div className="mt-2 has-text-centered">
        <center className="pb-3">
          <div>
            <img className="imgshowh" src={imgUrl} alt={socialMetaData?.title + t(' image')} />
          </div>
          <div className="pt-3">
            <a href="https://revopic.com/es/help-attendee/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare" target="_blank" rel="noopener noreferrer" className="image">
              <img className="is-rounded" style={{ width: '160px' }} src={posterUrl} alt="Default Poster Revopic" />
            </a>
          </div>

          <div className="has-text-centered pt-2 pb-2 pl-2 pr-2">
            <h3>{t('¿Tu también has participado en ')}<strong>{socialMetaData?.title}</strong>?</h3>
            <h5>{t('Puedes utilizar el código ')}<strong>{code}</strong>{t(' para acceder a tus fotos en ')}<a href="https://revopic.com/es/help-attendee/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare" target="_blank" rel="noopener noreferrer"><strong>{t('nuestra app')}</strong></a></h5>
          </div>
        </center>
        <p className="pb-3">
          Powered by <strong><a href="https://revopic.com/es/?utm_source=web&utm_medium=photoshare&utm_campaign=photoshare">Revopic</a></strong>
        </p>
      </div>

      <footer style={{ backgroundColor: '#e4efeb', display: 'none' }}></footer>
      {/* <img src={imgUrl} alt="Imagen Revopic" className="responsive-image" /> */}
      {socialMetaData && (
        <Helmet>
          <title>{"Mi foto en "+ socialMetaData?.title + " con Revopic"}</title>
          <meta name="description" content={socialMetaData?.description} />
          <meta name="image" content={socialMetaData?.image} />
          <meta name="url" content={socialMetaData?.url} />

          {/* Twitter */}
          <meta name="twitter:card" content={socialMetaData?.twitterCard} />
          <meta name="twitter:site" content={socialMetaData?.twitterSite} />
          <meta name="twitter:creator" content={socialMetaData?.twitterCreator} />

          {/* Open Graph/Facebook */}
          <meta property="og:title" content={socialMetaData?.ogTitle} />
          <meta property="og:description" content={socialMetaData?.ogDescription} />
          <meta property="og:image" content={socialMetaData?.ogImage} />
          <meta property="og:url" content={socialMetaData?.ogUrl} />
        </Helmet>
      )}
    </>
  );

}
export default Photo;